import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react"
import { WindowLocation } from "@reach/router"
import { navigate } from "gatsby"
import queryString from "query-string"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import SimpleLayout from "../components/simpleLayout"
import UserContext from "../context/userContext"
import { api } from "../settings"
import { apiFetch } from "../utility/userFetch"
import { trackCustomEvent } from "../utility/tracking"

export default function Activatie({ location }: { location: WindowLocation }) {
  const { state, dispatch } = useContext(UserContext)

  const parsed = queryString.parse(location.search)
  const activateCode = parsed.id
  const [codeValid, setCodeValid] = useState(false)
  useEffect(() => {
    if (activateCode) {
      setCodeValid(true)
    }
  }, [activateCode])

  const {
    register,
    handleSubmit,
    watch,
    formState: { isDirty },
    errors,
  } = useForm({ mode: "onBlur" })
  const [isLoading, setIsLoading] = useState(false)
  const login = useCallback(
    (x: any) => {
      setIsLoading(true)

      apiFetch("ActivateSubmit", {
        body: JSON.stringify({
          Code: activateCode ?? x.id,
          Email: x.email,
          Password: x.password,
        }), // body data type must match "Content-Type" header
      })
        .then(a => a.json())
        .then(a => {
          if (a.isValid) {
            localStorage.setItem("jwt", a.jwt)
            dispatch({
              ...state,
              jwt: a.jwt,
            })
            navigate("/mutate")
            trackCustomEvent({
              category: "KioskOwner",
              action: "ActivateAccount",
            })
          } else {
            alert(a.errorMessage)
          }
        })
        .finally(() => setIsLoading(false))
    },
    [state, dispatch]
  )

  const passwordCurrent = watch("password", "")

  return (
    <SimpleLayout title="Login" location={location}>
      <Flex width="full" align="center" justifyContent="center">
        <Box p={2}>
          <Box textAlign="center">
            <Heading>Activatie</Heading>
          </Box>
          <Box my={4} textAlign="left">
            <form onSubmit={handleSubmit(login)}>
              {!activateCode && (
                <FormControl isInvalid={errors.id != undefined}>
                  <FormLabel>Activatie code</FormLabel>
                  <Input
                    type="text"
                    name="id"
                    errorBorderColor="crimson"
                    ref={register({
                      validate: async value => {
                        if ((value ?? "").length < 4) {
                          return "Ongeldige activatie code"
                        }
                        let result = await fetch(
                          api + "/api/ActivateCheckTrigger?id=" + value
                        ).then(x => x.text())

                        setCodeValid(result === "true")
                        return result === "true"
                          ? undefined
                          : "Ongeldige activatie code"
                      },
                    })}
                    isRequired
                  />
                  <FormErrorMessage>{errors.id?.message}</FormErrorMessage>
                </FormControl>
              )}
              {codeValid && (
                <>
                  <FormControl mt={6} isInvalid={errors.email != undefined}>
                    <FormLabel>E-mailadres</FormLabel>
                    <Input
                      type="email"
                      placeholder=""
                      name="email"
                      ref={register({
                        validate: async value => {
                          let result = await fetch(
                            api + "/api/EmailIsValid?id=" + value
                          ).then(x => x.text())
                          return result === "false"
                            ? "E-mailadres is reeds bezet"
                            : undefined
                        },
                      })}
                      isRequired
                    />
                    <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl mt={6}>
                    <FormLabel>Wachtwoord (minimaal zes tekens)</FormLabel>
                    <Input
                      type="password"
                      placeholder="*******"
                      name="password"
                      ref={register({
                        minLength: 6,
                      })}
                      isRequired
                    />
                    <FormErrorMessage>
                      {errors.password?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    mt={6}
                    isInvalid={errors.passwordRepeat != undefined}
                  >
                    <FormLabel>Herhaal wachtwoord</FormLabel>
                    <Input
                      type="password"
                      placeholder="*******"
                      name="passwordRepeat"
                      ref={register({
                        validate: value =>
                          value === passwordCurrent ||
                          "Wachtwoorden komen niet overeen.",
                      })}
                      isRequired
                    />
                    <FormErrorMessage>
                      {errors.passwordRepeat?.message}
                    </FormErrorMessage>
                  </FormControl>
                </>
              )}
              <Button width="full" mt={4} type="submit" isLoading={isLoading}>
                Activeer
              </Button>
            </form>
          </Box>
        </Box>
      </Flex>
      <div style={{ textAlign: "center" }}>
        heeft u nog vragen? <br /> Stuur een e-mail naar hi@fairsy.nl (bij
        voorkeur),
        <br />
        whatsapp of bel 06-83084433
      </div>
    </SimpleLayout>
  )
}
